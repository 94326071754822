export const resources = {
  Audio: '',
  Video: '',
  Cloud: '',
  Edge: '',
  Live: '',
  Batch: '',
  EdgeLive: '',
  EdgeBatch: ''
};
