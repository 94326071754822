import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState } from '../reducers/live-edge.reducer';
import * as fromLive from '../reducers/live-edge.reducer';

export const selectLiveEdgeState = createFeatureSelector<IState>(fromLive.liveFeatureKey);

export const selectModelsState = createSelector(selectLiveEdgeState, state => state.models);

export const selectBuiltInModel = createSelector(selectModelsState, state => state.builtInModels);

export const getModels = createSelector(selectBuiltInModel, builtInModel => {
  return builtInModel.reduce((models, item) => {
    item.includedInsights.forEach(insightObj => {
      const key = `${item.type}_${insightObj.insightType}`;
      models.push({
        key,
        id: item.id,
        type: item.type,
        name: insightObj.insightType
      });
    });
    return models;
  }, []);
});

export const isModelsLoaded = createSelector(selectModelsState, state => state.loaded);
