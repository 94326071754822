import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState } from '../reducers/live-edge.reducer';
import { AffectedCamerasStatus } from '../components/live-ai-models-and-presets-page/live-presets-tab/components/apply-preset/interfaces';
import * as fromLive from '../reducers/live-edge.reducer';

export const selectLiveEdgeState = createFeatureSelector<IState>(fromLive.liveFeatureKey);

export const selectPresetsState = createSelector(selectLiveEdgeState, state => state.presets);

export const selectPresetsEntities = createSelector(selectPresetsState, state => state.entities);

export const selectPresets = createSelector(selectPresetsEntities, entities => Object.values(entities));

export const getPresetsDetails = createSelector(selectPresets, presets => {
  return presets.map(preset => {
    return {
      id: preset.id,
      name: preset.name,
      affectedCamerasCount: preset.affectedCamerasCount,
      affectedCamerasStatus: preset.affectedCamerasStatus as AffectedCamerasStatus,
      lastModified: preset.lastUpdateTime,
      includedInsights: preset.models.models.reduce((acc, model) => {
        return acc.concat(
          model.includedInsights.map(insight => {
            return `${insight.insightType} - ${model.type}`;
          })
        );
      }, [])
    };
  });
});

export const selectIsPresetsLoaded = createSelector(selectPresetsState, state => state.loaded);

export const getPresetError = createSelector(selectPresetsState, state => state.error);

export const selectPresetsPageTab = createSelector(selectPresetsState, state => state.page.selectedTab);

export const selectPreset = id => createSelector(selectPresetsEntities, entities => entities[id]);

export const selectPresetName = id => createSelector(selectPresetsEntities, entities => entities[id]?.name);

export const selectPresetModels = id => createSelector(selectPresetsEntities, entities => entities[id]?.models.models);

export const getPresetModelsInsights = id =>
  createSelector(selectPresetModels(id), models => {
    return models?.reduce((models, item) => {
      item.includedInsights.forEach(insight => {
        const key = `${item.type}_${insight.insightType}`;
        models.push({
          key,
          id: item.id,
          type: item.type,
          name: insight
        });
      });
      return models;
    }, []);
  });

export const selectAffectedCamerasCount = id => createSelector(selectPreset(id), preset => preset.affectedCamerasCount);
