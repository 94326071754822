import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState } from '../reducers/live-edge.reducer';
import * as fromLive from '../reducers/live-edge.reducer';
import * as fromCore from '../../../core/selectors';
import { RetentionPolicy } from '../components/live-camera-management';

import ICamera = Microsoft.VideoIndexer.Live.Contracts.ICamera;

export const selectLiveEdgeState = createFeatureSelector<IState>(fromLive.liveFeatureKey);

export const selectAllCameras = createSelector(selectLiveEdgeState, state => state.cameras.cameras);
export const selectPinnedCameras = createSelector(selectAllCameras, (cameras: ICamera[]) => cameras.filter(camera => camera.pinned));

export const getCameraSettings = createSelector(fromCore.isLiveModuleLoaded, isLiveModuleLoaded => {
  if (!isLiveModuleLoaded) {
    return null;
  }

  return {
    // @TODO: implement after camera management store integration
    recording: true,
    streaming: false,
    retentionPolicy: RetentionPolicy.keep,
    retentionDays: 5
  };
});
