import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

export const cancelAction: IAction = {
  title: 'Cancel',
  key: 'Cancel',
  type: UIActionType.CANCEL,
  value: 'Cancel',
  id: 'cancel'
};

export const saveAction: IAction = {
  title: 'Save',
  key: 'Save',
  type: UIActionType.SAVE,
  value: 'save',
  id: 'save'
};
