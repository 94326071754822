export const resources = {
  ExtensionDetailsIndexedMinutesLabel: '',
  ManageOnAzurePortal: '',
  Presets: '',
  LiveManagePresets: '',
  LiveManageCameras: '',
  ExtensionDetailsCreationDateLabel: '',
  ExtensionDetailsConnectedCamerasLabel: '',
  LiveCamerasPluralLabel: '',
  ExtensionName: '',
  k8sClusterEnabledByArc: '',
  azureIOTResourceLabel: ''
};
