export const resources = {
  Cancel: '',
  Save: '',
  On: '',
  Off: '',
  Days: '',
  Updating: '',
  LiveCameraSingularLabel: '',
  LiveCamerasPluralLabel: '',
  LiveCameraSettingsGroup1Label: '',
  LiveCameraSettingsGroup1Description: '',
  LiveCameraSettingsGroup2Label: '',
  LiveCameraSettingsGroup2Description: '',
  LiveCameraSettingsGroup3Label: '',
  LiveCameraSettingsGroup3Description: '',
  LiveCameraSettingsGroup3RadioOption1: '',
  LiveCameraSettingsGroup3RadioOption2: '',
  LiveCameraSettingsAlert1: ''
};
