export interface IPresetDetails {
  id: string;
  name: string;
  includedInsights: string[];
  affectedCamerasCount?: number;
  affectedCamerasStatus?: AffectedCamerasStatus;
  lastModified?: string;
}

export enum AffectedCamerasStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  ERROR = 'ERROR'
}

export enum ApplyPresetMode {
  APPLY = 'apply',
  UPDATE = 'update'
}
