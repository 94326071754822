import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';

import * as camerasActions from '../actions/live-cameras.actions';

import ICamera = Microsoft.VideoIndexer.Live.Contracts.ICamera;

export interface IState extends EntityState<ICamera> {
  loaded: boolean;
  isLoading: boolean;
  error: boolean;
  cameras: Microsoft.VideoIndexer.Live.Contracts.ICamera[];
}

export const adapter: EntityAdapter<ICamera> = createEntityAdapter<ICamera>();

export const initialState: IState = adapter.getInitialState({
  loaded: false,
  isLoading: false,
  error: false,
  cameras: []
});

const liveCamerasReducer = createReducer(
  initialState,
  on(camerasActions.manageCamerasClicked, state => {
    return {
      ...state,
      error: false,
      loaded: true,
      isLoading: false,
      cameras: state.cameras
    };
  }),
  on(camerasActions.viewCameraSettingsClicked, state => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(camerasActions.unpinCamera, (state, { cameraId }) => {
    return {
      ...state,
      cameras: state.cameras.map(camera => {
        if (camera.id === cameraId) {
          return {
            ...camera,
            pinned: false
          };
        }
        return camera;
      })
    };
  }),
  on(camerasActions.listCameras, state => {
    return {
      ...state,
      isLoading: true,
      loaded: false
    };
  }),
  on(camerasActions.searchCameras, state => {
    return {
      ...state,
      isLoading: true,
      loaded: false
    };
  }),
  on(camerasActions.listCamerasSuccess, (state, { cameras }) => {
    return {
      ...state,
      cameras,
      isLoading: false,
      loaded: true
    };
  }),
  on(camerasActions.listCamerasFailure, state => {
    return {
      ...state,
      isLoading: false,
      loaded: false,
      error: true
    };
  }),
  on(camerasActions.searchCamerasSuccess, (state, { filteredCameras }) => {
    return {
      ...state,
      filteredCameras,
      isLoading: false,
      loaded: true
    };
  }),
  on(camerasActions.searchCamerasFailure, state => {
    return {
      ...state,
      isLoading: false,
      loaded: false,
      error: true
    };
  }),
  on(camerasActions.pinCamera, (state, { cameraId }) => {
    return {
      ...state,
      cameras: state.cameras.map(camera => {
        if (camera.id === cameraId) {
          return {
            ...camera,
            pinned: true
          };
        }
        return camera;
      })
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return liveCamerasReducer(state, action);
}
