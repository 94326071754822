import { createAction, props } from '@ngrx/store';

import { Preset } from '../reducers/live-presets.reducer ';
import { INextPage } from '../../../gallery/core/models/gallery';
import { PresetsTab } from '../components/live-ai-models-and-presets-page/interfaces';
import { IPresetDetails } from '../components/live-ai-models-and-presets-page/live-presets-tab/components/apply-preset/interfaces';

export const loadPresets = createAction('[Live Presets/API] Load presets');

export const loadPresetsWithAffectedCamerasCount = createAction('[Live Presets/API] Load presets with affected cameras count');

export const loadPresetsSuccess = createAction(
  '[Live Presets/API] Loading presets success',
  props<{ presets: Microsoft.VideoIndexer.Live.Contracts.IPreset[]; nextPage?: INextPage }>()
);

export const getPresetSuccess = createAction('[Live Presets/API] Get preset success', props<{ preset: Preset }>());

export const getPresetFailed = createAction('[Live Presets/API] Get preset failed');

export const loadPresetsFailed = createAction('[Live Presets/API] Loading presets failed');

export const createPresetSuccess = createAction('[Live Presets/API] Create preset success', props<{ preset: Preset }>());

export const updatePreset = createAction(
  '[Live Presets/API] Update preset',
  props<{ presetId: string; data: Microsoft.VideoIndexer.Live.Contracts.IPresetUpdate }>()
);

export const updatePresetSuccess = createAction('[Live Presets/API] Update preset success', props<{ preset: Preset }>());

export const updatePresetFailed = createAction('[Live Presets/API] Update preset failed');

export const getAffectedCamerasCountFailed = createAction('[Live Presets/API] Get affected cameras count failed', props<{ presetId: string }>());

export const createPreset = createAction(
  '[Live Presets/API] Create preset',
  props<{ preset: Microsoft.VideoIndexer.Live.Contracts.IPresetUpdate }>()
);

export const createPresetFailed = createAction('[Live Presets/API] Create preset failed');

export const viewCamerasClicked = createAction('[Live Presets] View cameras by preset id', props<{ presetId: string; openInNewTab?: boolean }>());

export const viewAiInsightsClicked = createAction('[Live Presets] View AI Insights');

export const openCreatePresetDialog = createAction('[Live Presets] Open create preset dialog', props<{ presetId?: string }>());

export const openEditPresetDialog = createAction('[Live Presets] Open edit preset dialog', props<{ presetId: string }>());

export const applyPreset = createAction('[Live Presets/API] Apply preset', props<{ presetId: string; camerasIds: string[] }>());

export const applyPresetSuccess = createAction(
  '[Live Presets/API] Apply preset success',
  props<{ camera: Microsoft.VideoIndexer.Live.Contracts.ICamera }>()
);

export const applyPresetFailed = createAction('[Live Presets/API] Apply preset failed');

export const managePresetsClicked = createAction('[Live Presets] Manage presets clicked');

export const openApplyPresetDialog = createAction(
  '[Live Presets] Open apply preset dialog',
  props<{ cameras: Microsoft.VideoIndexer.Live.Contracts.ICamera[] }>()
);

export const presetTabSelected = createAction('[Live Presets] Select presets tab', props<{ tab: PresetsTab }>());

export const presetsTabChanged = createAction('[Live Presets] Presets tab changed', props<{ tab: PresetsTab }>());

export const openDeletePresetDialog = createAction('[Live Presets] Open delete preset dialog', props<{ presets: IPresetDetails[] }>());

export const deletePreset = createAction('[Live Presets/API] Delete preset', props<{ presetsIds: string[] }>());

export const deletePresetSuccess = createAction('[Live Presets/API] Delete preset success', props<{ presetId: string }>());

export const deletePresetFailed = createAction('[Live Presets/API] Delete preset failed');
