export enum RetentionPolicy {
  keep = 'keep',
  delete = 'delete'
}

export interface ILiveCameraSettings {
  retentionPolicy: RetentionPolicy;
  retentionDays?: number;
  streaming: boolean;
  recording: boolean;
}
