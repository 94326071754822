import { createAction, props } from '@ngrx/store';

export const loadModels = createAction('[Live Models/API] Load models', props<{ force?: boolean }>());

export const loadModelsInProgress = createAction('[Live Models/API] Load models in progress');

export const loadModelsSuccess = createAction(
  '[Live Models] Load models success',
  props<{ models: Microsoft.VideoIndexer.Live.Contracts.BuiltInModel[] }>()
);

export const loadModelsFailed = createAction('[Live Models] Load models failed');
