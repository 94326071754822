import { Params } from '@angular/router';

import { IHttpWrapper, IRequestParam } from '../interfaces';
import { concatParamsListToURL, getRequestSettings } from '../utils/request.function';

export class EdgeExtensionsApi {
  public constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase();
  }

  private get apiInternalBase(): string {
    return this.ApiWrapper.GetInternalApiBase();
  }

  // [HttpPost]
  public translate(accountId: string, videoId: string, language: Microsoft.VideoIndexer.Contracts.LanguageV2, reqSettings?: object) {
    const url = `${this.apiOperationBase}/accounts/${accountId}/videos/${videoId}/translate?language=${language}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.post<void>(url, null, requestSettings);
  }

  // [HttpPost]
  public uploadFile(accountId: string, file: File, reqParams?: Params, paramsList?: IRequestParam[]) {
    const serializedParams = this.ApiWrapper.UrlUtility.paramsToUrl(reqParams);
    let url = `${this.apiOperationBase}/accounts/${accountId}/Videos?${serializedParams}`;
    // Add list of params to the url. e.g. ?excludeAI=Faces&excludeAI=Labels...
    url = concatParamsListToURL(url, paramsList);
    const formData: FormData = new FormData();

    if (file) {
      formData.append('fileName', file);
    }

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, formData, {
      headers: {
        Accept: 'text/plain'
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  // [HttpGet]
  public list(reqSettings?: object) {
    const url = `${this.ApiWrapper.GetAuthApiBase()}/arm/edge/extensions`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension[]>(url, requestSettings);
  }

  // [HttpGet]
  public get(resourceId: string, reqSettings?: object) {
    const url = `${this.ApiWrapper.GetAuthApiBase()}/arm/edge${resourceId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension>(url, requestSettings);
  }

  // [HttpGet]
  public getDetails(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/accounts/${accountId}/extension`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IExtensionDetails>(url, requestSettings);
  }

  // [HttpGet]
  public handshake(reqSettings?: object) {
    const url = `${this.apiOperationBase}/info`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IExtensionInfo>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/LanguageModels]
  public listLanguageModels(accountId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/accounts/${accountId}/LanguageModels`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Arc.Common.LanguageModels>(url, requestSettings);
  }
}
