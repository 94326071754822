import { Subscription } from 'rxjs/internal/Subscription';

import { IInsightsSortedBy as CustomInsightSortedBy, insightsType } from '@azure/video-indexer-widgets';
import { ICustomItemElement } from '@azure/video-indexer-widgets';

import { IInsightGroupedKey, IInsightJsonKeyType } from '../insights-common/interfaces';
import { IAction, IStyle } from '../shared/interfaces';
import { IEntityTitles } from './components/entity-info/interfaces';
import { IUIPresetType } from './presetInsights';

export interface IVIInsightsEmbedUrlQueryParams {
  accessToken?: string;
  locale?: string;
  tab?: string;
  widgets?: string;
  customIndexLocation?: string;
  customImagesLocation?: string;
  preset?: string;
  language?: string;
  controls?: string;
  location?: string;
  search?: string;
  sort?: string;
}

export interface IComponentMapItem {
  selected: boolean;
  disabled?: boolean;
}

export interface IInsightsComponentMap {
  faces: IComponentMapItem;
  keywords: IComponentMapItem;
  audioEffects: IComponentMapItem;
  emotions: IComponentMapItem;
  speakers: IComponentMapItem;
  sentiments: IComponentMapItem;
  shots: IComponentMapItem;
  labels: IComponentMapItem;
  ocr: IComponentMapItem;
  transcript: IComponentMapItem;
  topics: IComponentMapItem;
  scenes: IComponentMapItem;
  namedEntities: IComponentMapItem;
  spokenLanguage: IComponentMapItem;
  observedPeople: IComponentMapItem;
  clapperboards: IComponentMapItem;
  colorBars: IComponentMapItem;
  logos: IComponentMapItem;
  detectedObjects: IComponentMapItem;
}

export interface ITimelineComponentMap {
  faces: IUIPresetInsight;
  keywords: IUIPresetInsight;
  audioEffects: IUIPresetInsight;
  emotions: IUIPresetInsight;
  speakers: IUIPresetInsight;
  sentiments: IUIPresetInsight;
  shots: IUIPresetInsight;
  labels: IUIPresetInsight;
  ocr: IUIPresetInsight;
  transcript: IUIPresetInsight;
  topics: IUIPresetInsight;
  scenes: IUIPresetInsight;
  namedEntities: IUIPresetInsight;
  spokenLanguage: IUIPresetInsight;
  observedPeople: IUIPresetInsight;
  clapperboards: IUIPresetInsight;
  colorBars: IUIPresetInsight;
  logos: IUIPresetInsight;
  detectedObjects: IUIPresetInsight;
}

export enum WidgetsParamOption {
  people = 'people',
  faces = 'faces',
  keywords = 'keywords',
  audioEffects = 'audioEffects',
  labels = 'labels',
  sentiments = 'sentiments',
  emotions = 'emotions',
  topics = 'topics',
  shots = 'shots',
  keyframes = 'keyframes',
  transcript = 'transcript',
  ocr = 'ocr',
  speakers = 'speakers',
  scenes = 'scenes',
  namedEntities = 'namedEntities',
  spokenLanguage = 'spokenLanguage',
  observedPeople = 'observedPeople',
  clapperboards = 'clapperboards',
  colorBars = 'colorBars',
  logos = 'logos',
  detectedObjects = 'detectedObjects'
}

export enum ControlsParamOption {
  search = 'search',
  download = 'download',
  presets = 'presets',
  language = 'language'
}

export type InsightsMap = {
  [key in WidgetsParamOption]: string;
};

export type ControlsMap = {
  [key in ControlsParamOption]: boolean;
};

export interface ISubscriptions {
  [key: string]: Subscription;
}

export enum IUITab {
  INSIGHTS,
  TIMELINE
}

export interface IWidgetExpandable {
  expanded?: boolean;
  overflowValue?: number;
  overflowSentence?: string;
  overflowWidth?: number;
}

export enum UIActionType {
  INDEXING_SETTINGS = 'INDEXING_SETTINGS',
  DOWNLOAD = 'DOWNLOAD',
  LANGUAGE = 'LANGUAGE',
  PRESETS = 'PRESETS',
  EDIT = 'EDIT',
  PLAY = 'PLAY',
  TIMELINE_AUTO_SCROLL = 'TIMELINE_AUTO_SCROLL',
  EDIT_TEXT = 'EDIT_TEXT',
  EDIT_METADATA = 'EDIT_METADATA',
  RENAME = 'RENAME',
  SET_AS_THUMB = 'SET_AS_THUMB',
  ADD = 'ADD',
  PRIVACY = 'PRIVACY',
  PRIVACY_SET_PRIVATE = 'PRIVACY_SET_PRIVATE',
  PRIVACY_SET_PUBLIC = 'PRIVACY_SET_PUBLIC',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  SAVE = 'SAVE',
  APPLY = 'APPLY',
  DELETE = 'DELETE',
  DELETE_PERSON = 'DELETE_PERSON',
  DELETE_FACE = 'DELETE_FACE',
  EXPANDED = 'EXPANDED',
  DROPDOWN = 'DROPDOWN',
  CREATE = 'CREATE',
  PREVIEW = 'PREVIEW',
  CANCEL = 'CANCEL',
  UPLOAD = 'UPLOAD',
  SCROLL = 'SCROLL',
  EMBED = 'EMBED',
  EDITOR = 'EDITOR',
  INFO = 'INFO',
  CLICK = 'CLICK',
  OPEN = 'OPEN',
  OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB',
  NAVIGATE = 'NAVIGATE',
  IMAGE = 'IMAGE',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  CLOSE = 'CLOSE',
  BUTTON = 'BUTTON',
  HIDE = 'HIDE',
  COPY = 'COPY',
  DUPLICATE = 'DUPLICATE',
  TRAIN = 'TRAIN',
  SEARCH = 'SEARCH',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  RE_INDEX = 'RE_INDEX',
  UPDATE = 'UPDATE',
  GRID = 'GRID',
  LIST = 'LIST',
  GRID_MINI = 'GRID_MINI',
  LIST_MINI = 'LIST_MINI',
  FILE_STATUS = 'FILE_STATUS',
  GROUP = 'GROUP',
  COMPACT = 'COMPACT',
  SETTINGS = 'SETTINGS',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  NEW_SPEAKER = 'NEW_SPEAKER',
  RENAME_SPEAKER = 'RENAME_SPEAKER',
  UNASSIGNED_SPEAKER = 'UNASSIGNED_SPEAKER',
  EXISTING_SPEAKER = 'EXISTING_SPEAKER',
  SWITCH_DIRECTORY = 'SWITCH_DIRECTORY',
  SORT = 'SORT',
  SELECT_PERSON_ROW = 'SELECT_PERSON_ROW',
  SELECT_PERSON_MODEL = 'SELECT_PERSON_MODEL',
  MERGE_PEOPLE = 'MERGE_PEOPLE',
  REGROUP = 'REGROUP',
  TOGGLE = 'TOGGLE',
  STYLE = 'STYLE',
  LENGTH = 'LENGTH',
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
  MENU = 'MENU'
}

export interface IUIPresetInsight {
  name: string;
  value: UIPresetInsight;
  selected: boolean;
  dataKey?: IInsightJsonKeyType | IInsightGroupedKey;
  config: boolean;
  disabled?: boolean;
  ranking: number;
  availableTabs: IUITab[];
  type: PresetInsightType;
}

export interface IUICustomPresetInsight extends IUIPresetInsight {
  state: IUICustomPresetInsightState;
}

export interface IUICustomPresetInsightState {
  selected: boolean;
}

export enum PresetInsightType {
  REGULAR = 'regular',
  CUSTOM = 'custom'
}

export interface ICustomUIPresetInsight {
  name: string;
  value: string;
  selected: boolean;
  config: boolean;
  ranking: number;
  availableTabs: IUITab[];
}

export interface IUIPreset {
  name: string;
  value: IUIPresetType;
  insights: UIPresetInsight[];
  selected?: boolean;
}

export interface IWidgetComponent {
  widget: IWidget;
  widgetHeader?: IWidgetHeader;
  prepareData(): void;
  hasInsightsData?(): boolean;
  setWidgetTitle(): void;
}

export interface IWidget {
  ready: boolean;
  fallbackMessage: string;
}

export interface IWidgetHeader extends IWidgetExpandable {
  title: string;
  expanded: boolean;
  miniMetadata?: {
    partA?: string;
    partB?: string;
  };
  overflowValue?: number;
  sideActions?: IAction[];
  mainSideAction?: IAction;
}

export interface ICustomInsightsData {
  key: string;
  title: string;
  items:
    | ICustomItemElement[]
    | Microsoft.VideoIndexer.Contracts.CustomInsightsCapsuleAndTags[]
    | Microsoft.VideoIndexer.Contracts.CustomInsightsCapsule[];
  insightType: insightsType | Microsoft.VideoIndexer.Contracts.CustomInsightType;
  sortedBy?: CustomInsightSortedBy;
  presets: IUIPresetType[];
}

export interface IUIAppearance {
  adjustedStart?: number | string;
  startSeconds: number;
  endSeconds: number;
  type?: string;
  endTime?: string;
  startTime?: string;
}

export interface IUIBasicInsight {
  id: number;
  name: string;
}

export interface IUIInsight extends IUIBasicInsight {
  selected?: boolean;
  appearances: IUIAppearance[];
  confidence?: number;
  referenceUrl?: string;
  referenceType?: string;
  seenDuration?: number;
  percentage?: number;
  title?: string;
  description?: string;
  customClass?: boolean;
}

export type IUIInsightType = 'namedEntities' | 'labels' | 'keywords' | 'acousticEvents';

export enum UIInsightType {
  NAMED_ENTITIES = 'namedEntities',
  LABELS = 'labels',
  KEYWORDS = 'keywords'
}

export enum UIPresetInsight {
  OCR = 'OCR',
  KEYFRAMES = 'KEYFRAMES',
  KEYWORDS = 'KEYWORDS',
  PEOPLE = 'PEOPLE',
  SPEAKERS = 'SPEAKERS',
  SPOKEN_LANGUAGE = 'SPOKEN_LANGUAGE',
  TRANSCRIPT = 'TRANSCRIPT',
  LABELS = 'LABELS',
  BRANDS = 'BRANDS',
  EMOTIONS = 'EMOTIONS',
  TOPICS = 'TOPICS',
  SENTIMENTS = 'SENTIMENTS',
  SCENES = 'SCENES',
  NAMED_ENTITIES = 'NAMED_ENTITIES',
  ACOUSTIC_EVENTS = 'ACOUSTIC_EVENTS',
  OBSERVED_PEOPLE = 'OBSERVED_PEOPLE',
  MATCHED_PERSON = 'MATCHED_PERSON',
  POST_PRODUCTION = 'POST_PRODUCTION',
  LOGOS = 'LOGOS',
  DETECTED_OBJECTS = 'DETECTED_OBJECTS'
}

export enum IUIPostProductionInsights {
  COLOR_BARS = 'COLOR_BARS',
  CLAPPERBOARD = 'CLAPPERBOARD'
}

export interface ISearchEvent {
  id: string;
  query: string;
}

export enum UIActionIcon {
  EXPANDED_OPEN = 'i-vi-arrow-up',
  EXPANDED_CLOSE = 'i-vi-arrow-down',
  EXPANDED_OPEN_SMALL = 'i-vi-arrow-up-small',
  EXPANDED_CLOSE_SMALL = 'i-vi-arrow-down-small',
  TRASH = 'i-vi-trash',
  EDIT = 'i-vi-edit',
  PLAY = 'i-vi-play',
  EDITOR = 'i-vi-editor',
  CREATE = 'i-vi-add',
  DUPLICATE = 'i-vi-duplicate',
  DROPDOWN = 'i-vi-more',
  SETTINGS = 'i-vi-settings',
  CLOSE = 'i-vi-close-small',
  RENAME = 'i-vi-rename',
  UPLOAD = 'i-vi-upload',
  SORT = 'i-vi-sort',
  BUTTON = '',
  OK = 'i-vi-ok',
  SPINNER = 'i-vi-spinner',
  OPEN = 'i-vi-open',
  REFRESH = 'i-vi-refresh',
  ADD = 'i-vi-addto',
  ADD_BIG = 'i-vi-add-big',
  INDEX = 'i-vi-index',
  GRID = 'i-vi-grid-view',
  LIST = 'i-vi-list-view',
  CUSTOMIZATION = 'i-vi-customization',
  PREVIEW = 'i-vi-text-file',
  DOWNLOAD = 'i-vi-download',
  COMPACT = 'i-vi-compact-list-view',
  PROPERTIES = 'i-vi-properties',
  COPY = 'i-vi-copy',
  PREVIOUS = 'i-vi-back',
  MERGE = 'i-vi-merge',
  EXPORT = 'i-vi-export',
  INDEXING_SETTINGS = 'i-vi-view',
  THUMBS_DOWN = 'i-vi-thumbs-down',
  THUMBS_UP = 'i-vi-thumbs-up',
  HIDE = 'i-vi-hide',
  LIVE_FEED = 'i-vi-live-feed',
  MENU = 'i-vi-menu'
}

export enum InsightErrorIcon {
  ILLUSTRATION_ERROR = 'i-vi-illustration-error',
  ILLUSTRATION_ERROR_NO_RESULTS = 'i-vi-illustration-no-results'
}

export enum UIStripType {
  FACE_GATE = 'FACE_GATE'
}

export type EditableInsights = 'labels' | 'keywords' | 'brands';

export interface IUpdateInsights {
  type: EditableInsights;
  insights: Microsoft.VideoIndexer.Contracts.Label[] | Microsoft.VideoIndexer.Contracts.Keyword[] | Microsoft.VideoIndexer.Contracts.Brand[];
}

export interface IInstancesToAppearancesOutput {
  seenDurationRatio?: number;
  appearances: IUIAppearance[];
}

export enum PresetRanking {
  SPEAKERS = 0,
  KEYFRAMES = 1,
  SCENES = 2,
  SENTIMENTS = 3,
  EMOTIONS = 4,
  ACOUSTIC_EVENTS = 5,
  SPOKEN_LANGUAGE = 6,
  TOPICS = 7,
  OBSERVED_PEOPLE = 8,
  MATCHED_PERSON = 9,
  NAMED_ENTITIES = 10,
  LABELS = 11,
  KEYWORDS = 12,
  OCR = 13,
  PEOPLE = 14,
  TRANSCRIPT = 16,
  POST_PRODUCTION = 17,
  LOGOS = 18,
  DETECTED_OBJECTS = 19
}

export interface IInsightsError {
  title: string;
  icon?: string;
  description?: string;
  traceId?: string;
}

export interface IUIEntityInsight extends IUIInsight {
  loading?: boolean;
  texts?: IEntityTitles;
  appearTitle?: string;
  seenDurationRatio?: number;
  positionTiny?: number;
  positionSmall?: number;
  positionLarge?: number;
  spriteUrl: string;
  spriteIndex: number;
  disabled?: boolean;
  titleAttribute?: string;
  // style for face pile
  style: IStyle;
  // style for entity info
  selectedStyle?: IStyle;
  showTooltip?: boolean;
  videoId?: string;
}

export enum SpriteSize {
  TINY = 'TINY',
  SMALL = 'SMALL',
  LARGE = 'LARGE'
}

export interface IUpdateLineEvent {
  type: UIPresetInsight;
  instance: Microsoft.VideoIndexer.Contracts.TranscriptLine | Microsoft.VideoIndexer.Contracts.Ocr;
}

export enum SortableInsightsComponents {
  keywords = WidgetsParamOption.keywords,
  audioEffects = WidgetsParamOption.audioEffects,
  labels = WidgetsParamOption.labels,
  sentiments = WidgetsParamOption.sentiments,
  emotions = WidgetsParamOption.emotions,
  scenes = WidgetsParamOption.scenes,
  namedEntities = WidgetsParamOption.namedEntities,
  spokenLanguage = WidgetsParamOption.spokenLanguage,
  keyframes = WidgetsParamOption.keyframes
}

export enum InsightsSortedProperty {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  SEEN_DURATION = 'seenDuration',
  NAME = 'name',
  ID = 'id'
}

export enum InsightsSortedOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface IInsightsSortedBy {
  property: InsightsSortedProperty;
  order: InsightsSortedOrder;
}

export type ISortedComponent = {
  [key in SortableInsightsComponents]?: IInsightsSortedBy;
};
