@if (multi) {
<vi-ui-alert-message [type]="AlertMessageType.WARNING"
  [content]="resources.LiveCameraSettingsAlert1" />
}

<div class="group">
  <span class="secondary">
    {{cameraNameLabel}}:
  </span>
  {{cameraNames}}
</div>

<div class="group streaming">
  <vi-ui-label [value]="resources.LiveCameraSettingsGroup1Label" />
  <p>{{resources.LiveCameraSettingsGroup1Description}}</p>
  <vi-ui-switch [(checked)]="state.streaming"
    [debounceDelay]="false"
    (checkedChange)="updateStreamingState($event)" />
  <span>
    {{ state.streaming ? resources.On : resources.Off }}
  </span>
</div>

<div class="group recording">
  <vi-ui-label [value]="resources.LiveCameraSettingsGroup2Label" />
  <p>{{resources.LiveCameraSettingsGroup1Description}}</p>
  <vi-ui-switch [(checked)]="state.recording"
    [debounceDelay]="false"
    (checkedChange)="updateRecordingState($event)" />
  <span>
    {{ state.recording ? resources.On : resources.Off }}
  </span>
</div>

@if (state.recording) {
<div class="group retention-policy">
  <vi-ui-label [value]="resources.LiveCameraSettingsGroup3Label" />
  <p>{{resources.LiveCameraSettingsGroup3Description}}</p>
  <fluent-radio-group [value]="state.retentionPolicy"
    orientation="vertical"
    (change)="updateRetentionPolicy($event)">
    <ng-container>
      <fluent-radio [value]="RetentionPolicy.delete">{{resources.LiveCameraSettingsGroup3RadioOption1}}</fluent-radio>
      <fluent-radio [value]="RetentionPolicy.keep">{{resources.LiveCameraSettingsGroup3RadioOption2}}</fluent-radio>
    </ng-container>
  </fluent-radio-group>

  @if (state.retentionPolicy === RetentionPolicy.keep) {
  <div class="retention-days">
    <vi-ui-input type="number"
      [value]="state.retentionDays"
      [min]="1"
      [max]="999"
      (inputChanged)="updateRetentionDays($event)" /> {{resources.Days}}
  </div>
  }

</div>
}

<section class="footer">
  <div *ngIf="(loading$ | async) === true">
    <span class="spinner-container">
      <app-vi-spinner size="tiny"></app-vi-spinner>
    </span>
    {{ resources.Updating }}...
  </div>
  <div class="buttons-container">
    <app-vi-action-button [action]="actions.saveAction"
      [isDisabled]="!canSubmit() || (loading$ | async) === true"
      [type]="ActionButtonType.PRIMARY"
      [width]="'82px'"
      (actionClick)="saveButtonClicked.emit(state)">
    </app-vi-action-button>
    <app-vi-action-button [action]="actions.cancelAction"
      [type]="ActionButtonType.SECONDARY"
      [width]="'82px'"
      (actionClick)="cancelButtonClicked.emit()">
    </app-vi-action-button>
  </div>
</section>