import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PresetsTab } from '../components/live-ai-models-and-presets-page/interfaces';
import { AffectedCamerasStatus } from './../components/live-ai-models-and-presets-page/live-presets-tab/components/apply-preset/interfaces';
import * as PresetActions from '../actions/live-presets.actions';

import IPreset = Microsoft.VideoIndexer.Live.Contracts.IPreset;
import INextPage = Microsoft.VideoIndexer.Contracts.INextPage;

//TODO: those interfaces will be used in more than one place, so it should be moved to a shared location
export interface Preset extends IPreset {
  affectedCamerasCount?: number;
  affectedCamerasStatus?: AffectedCamerasStatus;
}

export interface IState extends EntityState<Preset> {
  loaded: boolean;
  error: boolean;
  nextPage?: INextPage;
  loadingNext?: boolean;
  page: {
    selectedTab: PresetsTab;
  };
}

export function selectPresetId(preset: Preset): string {
  return preset.id;
}

export const adapter: EntityAdapter<Preset> = createEntityAdapter<Preset>({
  selectId: selectPresetId
});

export const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false,
  nextPage: {
    pageSize: null,
    skip: null,
    done: false,
    totalCount: null
  },
  loadingNext: false,
  page: {
    selectedTab: PresetsTab.Presets
  }
});

const livePresetsReducer = createReducer(
  initialState,
  on(PresetActions.createPresetSuccess, (state, { preset }) => {
    return adapter.upsertOne({ ...preset, affectedCamerasCount: 0, affectedCamerasStatus: AffectedCamerasStatus.LOADED }, state);
  }),
  on(PresetActions.updatePresetSuccess, PresetActions.getPresetSuccess, (state, { preset }) => {
    return adapter.upsertOne(preset, state);
  }),
  on(PresetActions.loadPresets, PresetActions.loadPresetsWithAffectedCamerasCount, (state, {}) => {
    const newState = { ...state, loaded: false, error: false };
    return adapter.removeAll(newState);
  }),
  on(PresetActions.loadPresetsSuccess, (state, { presets, nextPage }) => {
    const newState = {
      ...state,
      error: false,
      loaded: true,
      nextPage
    };
    return adapter.addMany(presets, newState);
  }),
  on(PresetActions.loadPresetsFailed, (state, {}) => {
    const newState = { ...state, loaded: true, error: true };
    return adapter.removeAll(newState);
  }),
  on(PresetActions.presetTabSelected, (state, { tab }) => {
    return { ...state, page: { ...state.page, selectedTab: tab } };
  }),
  on(PresetActions.getAffectedCamerasCountFailed, (state, { presetId }) => {
    return adapter.updateOne(
      { id: presetId, changes: { affectedCamerasCount: undefined, affectedCamerasStatus: AffectedCamerasStatus.ERROR } },
      state
    );
  }),
  on(PresetActions.deletePresetSuccess, (state, { presetId }) => {
    return adapter.removeOne(presetId, state);
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return livePresetsReducer(state, action);
}
