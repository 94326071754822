<section class="grid">
  <span>
    {{resources.ExtensionDetailsIndexedMinutesLabel}}
    <span class="value">
      {{details.totalIndexedMinutes | number}}
    </span>
  </span>
  @if (shouldShowPresets) {
  <span>
    {{resources.Presets}}
    <div>
      <span class="value">
        {{details.numberOfPresets}} {{resources.Presets.toLowerCase()}}
      </span>
      <vi-ui-link role="button"
        [text]="resources.LiveManagePresets.toLowerCase()"
        (linkClicked)="managePresetsClicked.emit()" />
    </div>
  </span>
  }
  <span>
    {{resources.ExtensionDetailsCreationDateLabel}}
    <span class="value">
      {{details.createdAt | date:'mediumDate'}}
    </span>
  </span>
  @if (shouldShowCameras) {
  <span>
    {{resources.ExtensionDetailsConnectedCamerasLabel}}
    <div>
      <span class="value">
        {{details.numberOfCameras}} {{resources.LiveCamerasPluralLabel.toLowerCase()}}
      </span>
      <vi-ui-link role="button"
        [text]="resources.LiveManageCameras"
        (linkClicked)="manageCamerasClicked.emit()" />
    </div>
  </span>
  }
</section>
<section class="properties">
  <div class="row">
    {{resources.ExtensionName}}
    <div class="property">
      <span>
        {{extensionName}} ({{details.version}})
      </span>
      <app-vi-action-button [action]="manageAction"
        [type]="ActionButtonType.ACTION_BUTTON"
        (actionClick)="openLink(manageExtensionLink)" />
    </div>
  </div>

  <div class="row">
    {{resources.k8sClusterEnabledByArc}}
    <div class="property">
      <span>
        {{clusterName}}
      </span>
      <app-vi-action-button [action]="manageAction"
        [type]="ActionButtonType.ACTION_BUTTON"
        (actionClick)="openLink(manageClusterLink)" />
    </div>
  </div>

  @if (shouldShowAioResourceId) {
  <div class="row">
    {{resources.azureIOTResourceLabel}}
    <div class="property">
      <span>
        {{aioName}}
      </span>
      <app-vi-action-button [action]="manageAction"
        [type]="ActionButtonType.ACTION_BUTTON"
        (actionClick)="openLink(manageAIOLink)" />
    </div>
  </div>
  }
</section>