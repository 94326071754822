import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { AlertMessageType, VIUIModule } from '@vi-ui/src/lib/vi-ui.module';
import { fluentRadio, fluentRadioGroup, provideFluentDesignSystem } from '@fluentui/web-components';
import { cloneDeep } from 'lodash-es';
import { isEqual } from 'lodash-es';

import { SharedModule } from '@common/modules/shared/shared.module';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import * as actions from './actions';
import { resources } from './resources';
import { ILiveCameraSettings, RetentionPolicy } from './interfaces';

@Component({
  selector: 'vi-live-camera-settings',
  standalone: true,
  templateUrl: './live-camera-settings.component.html',
  styleUrls: ['./live-camera-settings.component.scss'],
  imports: [CommonModule, forwardRef(() => SharedModule), VIUIModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LiveCameraSettingsComponent implements OnInit {
  @Input() public settings: ILiveCameraSettings;
  @Input() public cameras: string[];
  @Input() public loading$: Observable<boolean>;
  @Output() public cancelButtonClicked = new EventEmitter<void>();
  @Output() public saveButtonClicked = new EventEmitter<ILiveCameraSettings>();

  public state: ILiveCameraSettings;
  public RetentionPolicy = RetentionPolicy;
  public ActionButtonType = ActionButtonType;
  public AlertMessageType = AlertMessageType;

  public actions = actions;
  public resources = resources;

  constructor(private translate: TranslateHelperService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    provideFluentDesignSystem().register(fluentRadio(), fluentRadioGroup());

    this.state = cloneDeep(this.settings);

    // in multi camera mode, we init both streaming and recording to false
    if (this.multi) {
      this.state.streaming = false;
      this.state.recording = false;
    }

    this.initTranslation();
  }

  public get multi() {
    return this.cameras?.length > 1;
  }

  public get cameraNames() {
    return this.cameras?.join(', ');
  }

  public get cameraNameLabel() {
    return this.multi ? this.resources.LiveCamerasPluralLabel : this.resources.LiveCameraSingularLabel;
  }

  public canSubmit() {
    return this.multi || !isEqual(this.state, this.settings);
  }

  public updateRetentionPolicy(event) {
    this.state.retentionPolicy = event?.currentTarget?.value;

    // if the user selects keep, we default the retention days to 1
    if (this.state.retentionPolicy === RetentionPolicy.keep) {
      this.state.retentionDays ||= 1;
    }
  }

  public updateRetentionDays(retentionDays: string) {
    this.state.retentionDays = Number(retentionDays);
  }

  public updateStreamingState(streaming: boolean) {
    this.state.streaming = streaming;
  }

  public updateRecordingState(recording: boolean) {
    this.state.recording = recording;
  }

  private initTranslation() {
    this.translate.translateResourcesInstant(resources);

    this.actions.saveAction.title = this.resources[this.actions.saveAction.key];
    this.actions.cancelAction.title = this.resources[this.actions.cancelAction.key];

    this.cdr.detectChanges();
  }
}
