import { Injectable } from '@angular/core';

import { capitalize } from 'lodash-es';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { toLocaleMonthFormattedString } from '@common/modules/utils/time';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { StorageCacheKey } from '@common/modules/shared/interfaces';

import { ModelCatalogItem } from '../../components/live-model-catalog-page/components';
import models from './data.json';
import { resources } from './resources';

interface ModelCatalog extends Omit<ModelCatalogItem, 'type' | 'environment'> {
  typeKeys: string[];
  environmentKeys: string[];
}

@Injectable({
  providedIn: 'root'
})
export class LiveModelCatalogService {
  public resources = resources;
  private _models: ModelCatalog[] = models;
  private _items: ModelCatalogItem[];

  public get items(): ModelCatalogItem[] {
    return this._items;
  }

  constructor(private translate: TranslateHelperService, private localStorageService: LocalStorageService) {
    this.translate.translateResourcesInstant(this.resources);
    this._items = this.mapModelsToModelCatalogItems();
  }

  public getModelByKey(modelKey: string): ModelCatalogItem {
    return this.items.find(item => item.key === modelKey);
  }

  private mapModelsToModelCatalogItems(): ModelCatalogItem[] {
    const translate = (arr: string[]) => arr.map(key => this.translate.instant(key));
    const format = (arr: string[]) => arr.map(key => capitalize(key)).join(' + ');
    const locale = this.localStorageService.getItem(StorageCacheKey.Locale) || 'en-us';

    return this._models.map(model => ({
      ...model,
      updatedAt: toLocaleMonthFormattedString(new Date(model.updatedAt), locale),
      isUpdated: this.getIsUpdated(model.updatedAt),
      version: model.version,
      provider: model.provider,
      type: format(translate(model.typeKeys)),
      environment: format(translate(model.environmentKeys))
    }));
  }

  /**
   * @TODO: update based on real behavior
   */
  private getIsUpdated(updatedAt: string): boolean {
    return new Date(updatedAt) > new Date(new Date().setDate(new Date().getDate() - 25));
  }
}
