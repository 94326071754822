import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { ApiService } from '@common/modules/api/services/api.service';
import { TrackService } from '@common/modules/core/services/track/track.service';
import { EventCategory } from '@common/modules/core/services/track';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { UserType } from '@common/modules/auth/interfaces';
import { CommonTrackingDataService } from '@common/modules/core/services/track/common-tracking-data.service';
import { getErrorType } from '@common/modules/api/utils/request.function';
import { IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { CacheStorageService, ICacheResponse } from '@common/modules/core/services/cache-storage';
import { TimeInterval } from '@common/modules/utils/time';

import { EdgeExtensionsApiHandlerService } from '../services/edge-extensions-api.handler.service';
import { EdgeExtensionsStoreService } from '../services/edge-extensions-store.service';
import * as actions from '../actions/index';
import * as LibraryActions from '../../gallery/core/actions/library.actions';
import * as GalleryActions from '../../gallery/core/actions/gallery.actions';
import * as AccountsActions from '../../core/actions/accounts.actions';
import * as fromDirectories from '../reducers/directories.reducer';
import * as fromCore from '../selectors';
import * as fromRouter from '../reducers/router';
import * as RouterActions from '../../core/actions/router.actions';
import { VIRoutingMap } from '../../app/routing/routes';
import { NotificationsHandlerService } from '../services/notifications-handler.service';
import { EdgeExtensionDetailsWrapperComponent } from '../../edge/components/extension-details-dialog';

import IConnectedClusterExtension = Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension;
import IExtensionDetails = Microsoft.VideoIndexer.Contracts.IExtensionDetails;
import IExtensionInfo = Microsoft.VideoIndexer.Contracts.IExtensionInfo;

@Injectable()
export class EdgeExtensionsEffects {
  public userLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addUser),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      switchMap(([{ user }, tenantId]) => {
        // TODO - hide edge extensions toggle until we have a better solution
        // const showArcExtensionsSettings = this.localStorageService.getItem(StorageCacheKey.ShowEdgeExtensions) === 'true';
        if (!this.isEdgeEnabled) {
          return [actions.loadEdgeExtensionsNotNeededForInit()];
        }

        const actionsList = [];
        const lastEdgeExtensionId = this.localStorageService.getItem(`${StorageCacheKey.LastExtension}_${tenantId}`);

        if (user.userType === UserType.MicrosoftCorpAad) {
          actionsList.push(actions.loadEdgeExtensions());
        }

        if (!lastEdgeExtensionId) {
          actionsList.push(actions.loadEdgeExtensionsNotNeededForInit());
        }

        return actionsList;
      })
    )
  );

  // getLastEdgeExtensionsDetails effect - gets the expanded details for the last edge extension if needed
  // called on select account change
  public getLastEdgeExtensionsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.loadLastArmAccount),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      withLatestFrom(this.store.select(fromRouter.isGalleryRoute)),
      switchMap(([[, tenantId], isGalleryRoute]) => {
        // TODO - hide edge extensions toggle until we have a better solution
        // const showArcExtensionsSettings = this.localStorageService.getItem(StorageCacheKey.ShowEdgeExtensions) === 'true';
        if (!this.isEdgeEnabled) {
          return EMPTY;
        }

        const lastEdgeExtensionId = this.localStorageService.getItem(`${StorageCacheKey.LastExtension}_${tenantId}`);
        if (lastEdgeExtensionId && isGalleryRoute) {
          return [actions.loadLastEdgeExtension()];
        } else {
          this.localStorageService.removeItem(`${StorageCacheKey.LastExtension}_${tenantId}`);
        }

        return EMPTY;
      })
    )
  );

  public loadExtensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadEdgeExtensions),
      switchMap(() => this.tryGetResponseFromCache()),
      switchMap((cache: ICacheResponse<IConnectedClusterExtension[]> = {}) => {
        const { json, date } = cache;

        if (json?.length) {
          this.trackService.track('edge.get_cached_extensions.success', {
            category: EventCategory.EDGE,
            data: {
              count: json.length
            }
          });

          // refresh cache in the background in case cache data is older than X minutes
          const refreshThreshold = TimeInterval.MINUTE * 10;
          const shouldRefreshData = new Date().getTime() - new Date(date).getTime() > refreshThreshold;

          if (shouldRefreshData) {
            this.store.dispatch(actions.refreshCachedExtensions());
          }

          return [actions.loadEdgeExtensionsSuccess({ extensions: json })];
        }

        // if no cached extensions found, fetch from api and update cache
        return this.apiService.EdgeExtensions.list().pipe(
          switchMap((extensions: IConnectedClusterExtension[] = []) => {
            this.trackService.track('edge.load_extensions.success', {
              category: EventCategory.EDGE,
              data: {
                count: extensions.length
              }
            });

            if (this.featureSwitchService.featureSwitch(FeatureSwitch.UseCacheApiEdge)) {
              this.cacheService.put(this.cacheService.resources.EdgeExtensions, extensions);

              this.trackService.track('edge.set_cached_extensions.success', {
                category: EventCategory.EDGE,
                data: {
                  count: extensions?.length
                }
              });
            }

            return [actions.loadEdgeExtensionsSuccess({ extensions })];
          }),
          catchError(error => {
            this.trackService.track('edge.load_extensions.failed', {
              category: EventCategory.EDGE,
              data: { error }
            });

            return [actions.loadEdgeExtensionsFailed()];
          })
        );
      })
    )
  );

  public refreshCachedExtensions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.refreshCachedExtensions),
        switchMap(_ => {
          return this.apiService.EdgeExtensions.list().pipe(
            map((extensions: IConnectedClusterExtension[] = []) => {
              this.cacheService.put(this.cacheService.resources.EdgeExtensions, extensions);

              this.trackService.track('edge.refresh_cached_extensions.success', {
                category: EventCategory.EDGE,
                data: {
                  count: extensions?.length
                }
              });
            }),
            catchError(error => {
              this.trackService.track('edge.refresh_cached_extensions.failed', {
                category: EventCategory.EDGE,
                data: {
                  error
                }
              });
              return EMPTY;
            })
          );
        })
      ),
    {
      dispatch: false
    }
  );

  public loadLastExtension$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadLastEdgeExtension),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      switchMap(([, tenantId]) => {
        const lastExtensionId = this.localStorageService.getItem(`${StorageCacheKey.LastExtension}_${tenantId}`);
        if (!lastExtensionId) {
          return EMPTY;
        }

        return this.apiService.EdgeExtensions.get(lastExtensionId).pipe(
          switchMap((result: IConnectedClusterExtension) => {
            this.trackService.track('edge.load_last_extension.success', {
              category: EventCategory.EDGE
            });

            return [AccountsActions.loadEdgeExtensionsSuccess({ extensions: [result] }), actions.selectEdgeExtension({ id: result?.id })];
          }),
          catchError(error => {
            this.trackService.track('edge.load_last_extension.failed', {
              category: EventCategory.EDGE,
              data: { error }
            });

            return [AccountsActions.loadEdgeExtensionsFailed()];
          })
        );
      })
    )
  );

  public loadEdgeExtensionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadEdgeExtensionDetails),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectedEdgeExtensionId)),
      switchMap(([[, accountId], extensionId]) => {
        return this.apiService.EdgeExtensions.getDetails(accountId).pipe(
          switchMap((result: IExtensionDetails) => {
            this.trackService.track('edge.get_extension_details.success', {
              category: EventCategory.EDGE,
              data: { extensionId, everUploadDurationInSeconds: result.quotaUsage.everUploadDurationInSeconds }
            });

            return [
              actions.loadEdgeExtensionDetailsSuccess({
                id: extensionId,
                details: result
              })
            ];
          }),
          catchError(error => {
            this.trackService.track('edge.get_extension_details.failed', {
              category: EventCategory.EDGE,
              data: { error }
            });

            return [actions.loadEdgeExtensionDetailsFailed()];
          })
        );
      })
    )
  );

  public openExtensionDetailsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.openExtensionDetailsDialog),
      switchMap(() => {
        this.store.dispatch(actions.loadEdgeExtensionDetails());
        this.trackService.track('edge.extension_details_dialog.opened', {
          category: EventCategory.LIVE
        });

        const dialogData: IDialogData = {
          class: 'extension-details-dialog',
          component: EdgeExtensionDetailsWrapperComponent,
          title: this.translate.instant('ExtensionDetailsDialogTitle'),
          secondaryButton: {
            type: ActionButtonType.SECONDARY,
            action: { title: this.translate.instant('Close'), value: null, id: 'close' }
          }
        };

        this.dialogService.openDialog(dialogData, '620px', '480px', 'extension-details-dialog-container', false, true, 'auto', true);

        return [];
      })
    )
  );

  public handshakeEdgeExtension$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.handshakeEdgeExtension),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      withLatestFrom(this.store.select(fromRouter.isGalleryRoute)),
      mergeMap(([[, tenantId], isGalleryRoute]) => {
        const lastExtensionId = this.localStorageService.getItem(`${StorageCacheKey.LastExtension}_${tenantId}`);
        if (!lastExtensionId) {
          return EMPTY;
        }
        setTimeout(() => {
          this.store.dispatch(LibraryActions.loadingLibrary());
        });

        return this.apiService.EdgeExtensions.handshake().pipe(
          // takeUntil is used to stop checking for handshake in case another extension is loaded
          takeUntil(this.actions$.pipe(ofType(actions.selectEdgeExtension))),
          mergeMap((result: IExtensionInfo) => {
            this.trackService.track('edge.get_info.success', {
              category: EventCategory.EDGE,
              data: {
                extensionId: result.extensionId
              }
            });

            if (result.extensionId?.toLowerCase() !== lastExtensionId?.toLowerCase()) {
              return [LibraryActions.loadLibraryError({})];
            }
            const actionsList = [];
            actionsList.push(actions.handshakeSuccess());

            if (isGalleryRoute) {
              actionsList.push(LibraryActions.loadLibrary());
            }

            return actionsList;
          }),
          catchError(error => {
            this.trackService.track('edge.get_info.failed', {
              category: EventCategory.EDGE,
              data: {
                error
              }
            });

            return [actions.handshakeFailed(), LibraryActions.loadLibraryError({ errorType: getErrorType(error) })];
          })
        );
      })
    )
  );

  public selectExtensionByPrincipalId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.selectEdgeExtensionByPrincipalId),
      withLatestFrom(this.store.select(fromCore.selectEdgeExtensions)),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      withLatestFrom(this.store.select(fromCore.selectedEdgeExtensionId)),
      switchMap(([[[{ principalId }, extensions], tenantId], selectedEdgeExtensionId]) => {
        const extension = extensions.find(e => e.identity?.principalId === principalId);
        this.localStorageService.setItem(`${StorageCacheKey.LastExtension}_${tenantId}`, extension?.id);

        if (selectedEdgeExtensionId === extension?.id) {
          return EMPTY;
        }

        return [actions.selectEdgeExtension({ id: extension?.id })];
      })
    )
  );

  public clearSelectedExtension$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.clearSelectedEdgeExtension),
        withLatestFrom(this.store.select(fromCore.getTenantId)),
        tap(([, tenantId]) => {
          this.notificationsHandlerService.clearSessionNotifications();
          this.localStorageService.removeItem(`${StorageCacheKey.LastExtension}_${tenantId}`);
        })
      ),
    { dispatch: false }
  );

  public selectExtension$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.selectEdgeExtension),
      withLatestFrom(this.store.select(fromCore.getTenantId)),
      switchMap(([{ id }, tenantId]) => {
        this.localStorageService.setItem(`${StorageCacheKey.LastExtension}_${tenantId}`, id);
        this.store.dispatch(LibraryActions.clearLibrary());

        return [actions.handshakeEdgeExtension()];
      })
    )
  );

  public selectEdgeExtensionClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.selectEdgeExtensionClicked),
      withLatestFrom(this.store.select(fromRouter.isLibraryRoute)),
      withLatestFrom(this.store.select(fromCore.selectedEdgeExtensionId)),
      switchMap(([[{ id }, isLibraryRoute], selectedEdgeExtensionId]) => {
        if (selectedEdgeExtensionId === id) {
          return EMPTY;
        }

        this.notificationsHandlerService.clearSessionNotifications();
        const actionsList = [];
        if (!isLibraryRoute) {
          actionsList.push(
            RouterActions.Go({
              path: [`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`],
              extras: null,
              queryParams: null,
              removeParams: true
            })
          );
        }

        actionsList.push(actions.selectEdgeExtension({ id }), GalleryActions.clearGallery());

        return actionsList;
      })
    )
  );

  private isEdgeEnabled = false;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromDirectories.IState>,
    private trackService: TrackService,
    private localStorageService: LocalStorageService,
    private extensionsApiHandlerService: EdgeExtensionsApiHandlerService,
    private edgeExtensionsStore: EdgeExtensionsStoreService,
    private commonTrackingDataService: CommonTrackingDataService,
    private featureSwitchService: FeatureSwitchService,
    private notificationsHandlerService: NotificationsHandlerService,
    private translate: TranslateHelperService,
    private dialogService: DialogService,
    private cacheService: CacheStorageService
  ) {
    this.isEdgeEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.Edge);

    if (this.isEdgeEnabled) {
      this.extensionsApiHandlerService.init();
      this.initTrackingDataService();
    }
  }

  private initTrackingDataService() {
    this.edgeExtensionsStore.selectedEdgeExtensionId$.subscribe(id => {
      this.commonTrackingDataService.edgeExtensionId = id;
    });
  }

  private tryGetResponseFromCache = (cacheKey = this.cacheService.resources.EdgeExtensions) => {
    if (!this.featureSwitchService.featureSwitch(FeatureSwitch.UseCacheApiEdge)) {
      return of({});
    }

    return this.cacheService.match(cacheKey, { maxAge: TimeInterval.DAY * 1 });
  };
}
