import { Component, EventEmitter, Output, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { EdgeExtensionsStoreService } from 'src/apps/web/src/core/services/edge-extensions-store.service';

import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';

import { ExtensionDetailsComponent } from '../extension-details';
import { LiveStoreService } from '../../../live/services/live-edge-store.service';
import { IExtensionDetails } from '../interfaces';

@Component({
  selector: 'vi-extension-details-wrapper',
  standalone: true,
  templateUrl: './extension-details-wrapper.component.html',
  imports: [ExtensionDetailsComponent, CommonModule]
})
export class EdgeExtensionDetailsWrapperComponent {
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public details$: Observable<IExtensionDetails>;

  constructor(public edgeExtensionsStore: EdgeExtensionsStoreService, @Optional() private liveStoreService: LiveStoreService) {}

  public onManageCameraClicked() {
    this.liveStoreService?.manageCamerasClicked();

    this.componentEventEmitter.emit({
      isDialogClose: true
    });
  }

  public onManagePresetsClicked() {
    this.liveStoreService?.managePresets();

    this.componentEventEmitter.emit({
      isDialogClose: true
    });
  }
}
