import { combineReducers } from '@ngrx/store';

import * as fromPresets from './live-presets.reducer ';
import * as fromModels from './live-models.reducer';
import * as fromCameras from './live-cameras.reducer';

export const liveFeatureKey = 'live';
export interface IState {
  presets: fromPresets.IState;
  models: fromModels.IState;
  cameras: fromCameras.IState;
}

const initialState: IState = {
  presets: fromPresets.initialState,
  models: fromModels.initialState,
  cameras: fromCameras.initialState
};

export const reducer = combineReducers({
  presets: fromPresets.reducer,
  models: fromModels.reducer,
  cameras: fromCameras.reducer
});
