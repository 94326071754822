import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, switchMap } from 'rxjs';

import * as liveState from '../reducers/live-edge.reducer';
import * as presetsActions from '../actions/live-presets.actions';
import * as modelsActions from '../actions/live-models.actions';
import * as cameraActions from '../actions/live-cameras.actions';
import * as fromModels from '../selectors/live-models.selectors';
import * as fromPresets from '../selectors/live-presets.selectors';
import * as fromLiveEdge from '../selectors/live-edge.selectors';
import * as fromCameras from '../selectors/live-cameras.selectors';
import { LiveModelCatalogService } from './live-model-catalog/live-model-catalog.service';
import { PresetParams } from '../components/live-ai-models-and-presets-page/live-presets-tab/components/preset-details-wrapper/interfaces';
import { IModel } from '../components/live-ai-models-and-presets-page/live-presets-tab/components/preset-details/interfaces';
import { IPresetDetails } from '../components/live-ai-models-and-presets-page/live-presets-tab/components/apply-preset/interfaces';
import { IExtensionDetails } from '../../components/extension-details-dialog/interfaces';
import { PresetsTab } from '../components/live-ai-models-and-presets-page/interfaces';
import { ILiveCameraSettings } from '../components/live-camera-management';

import ICameraUpdate = Microsoft.VideoIndexer.Live.Contracts.ICameraUpdate;

@Injectable({ providedIn: 'root' })
export class LiveStoreService {
  constructor(private readonly store: Store<liveState.IState>, private liveModelCatalogService: LiveModelCatalogService) {}

  public get extensionDetails$(): Observable<IExtensionDetails> {
    return this.store.select(fromLiveEdge.getExtensionDetailsDialogData);
  }

  // ==============================
  // AI Models
  // ==============================

  /**
   * Select models from the store and align them with the model catalog items
   * to obtain comprehensive information about the models that we are not receiving from the API
   */
  public get selectModelsInsights$(): Observable<IModel[]> {
    return this.store.select(fromModels.getModels).pipe(
      switchMap(insights => {
        const insightsList = insights.map(insight => {
          const modelCatalogItem = this.liveModelCatalogService.getModelByKey(insight.key);
          return {
            ...insight,
            description: modelCatalogItem?.description || '--',
            isNew: modelCatalogItem?.new,
            isUpdated: modelCatalogItem?.isUpdated
          };
        });
        return [insightsList];
      })
    );
  }

  public get isModelsLoaded$() {
    return this.store.select(fromModels.isModelsLoaded);
  }

  public loadModels() {
    this.store.dispatch(modelsActions.loadModels({}));
  }

  // ==============================
  // AI Presets
  // ==============================

  // getters

  public get selectPresets$() {
    return this.store.select(fromPresets.selectPresets);
  }

  public get isLoadPresetsError$() {
    return this.store.select(fromPresets.getPresetError);
  }

  public selectPreset(id: string) {
    return this.store.select(fromPresets.selectPreset(id));
  }

  public selectPresetName(id: string) {
    return this.store.select(fromPresets.selectPresetName(id));
  }

  public selectPresetIncludedModels(id: string) {
    return this.store.select(fromPresets.getPresetModelsInsights(id)).pipe(
      switchMap(insights => {
        const insightsList = insights.map(insight => {
          const modelCatalogItem = this.liveModelCatalogService.getModelByKey(insight.key);
          return {
            ...insight,
            description: modelCatalogItem?.description || '--',
            isNew: modelCatalogItem?.new,
            isUpdated: modelCatalogItem?.isUpdated
          };
        });
        return [insightsList];
      })
    );
  }

  public selectAffectedCamerasCount(id: string): Observable<number> {
    return this.store.select(fromPresets.selectAffectedCamerasCount(id));
  }

  public get selectPresetsDetailsList$(): Observable<IPresetDetails[]> {
    return this.store.select(fromPresets.getPresetsDetails);
  }

  public get isPresetsLoaded$() {
    return this.store.select(fromPresets.selectIsPresetsLoaded);
  }

  public get selectedPresetsPageTab$() {
    return this.store.select(fromPresets.selectPresetsPageTab);
  }

  public get getCameras$() {
    return this.store.select(fromCameras.selectAllCameras);
  }

  public get getPinnedCameras$() {
    return this.store.select(fromCameras.selectPinnedCameras);
  }

  public get cameraSettings$(): Observable<ILiveCameraSettings> {
    return this.store.select(fromCameras.getCameraSettings);
  }

  // actions

  public viewCamerasByPresetId(presetId: string, openInNewTab: boolean = false) {
    this.store.dispatch(presetsActions.viewCamerasClicked({ presetId, openInNewTab }));
  }

  public viewAiInsights() {
    this.store.dispatch(presetsActions.viewAiInsightsClicked());
  }

  public createPreset(preset: PresetParams) {
    const builtInModelsMap = new Map<string, Microsoft.VideoIndexer.Live.Contracts.BuiltInModel>();

    preset.models.forEach(model => {
      const { id, name: insight } = model;

      if (!builtInModelsMap.has(id)) {
        builtInModelsMap.set(id, {
          id,
          includedInsights: []
        });
      }

      builtInModelsMap.get(id).includedInsights.push({ insightType: insight });
    });
    const models = Array.from(builtInModelsMap.values());

    this.store.dispatch(presetsActions.createPreset({ preset: { name: preset.name, models } }));
  }

  public updatePreset(preset: PresetParams) {
    const builtInModelsMap = new Map<string, Microsoft.VideoIndexer.Live.Contracts.BuiltInModel>();

    preset.models.forEach(model => {
      const { id, name: insight } = model;

      if (!builtInModelsMap.has(id)) {
        builtInModelsMap.set(id, {
          id,
          includedInsights: []
        });
      }

      builtInModelsMap.get(id).includedInsights.push({ insightType: insight });
    });
    const models = Array.from(builtInModelsMap.values());
    this.store.dispatch(presetsActions.updatePreset({ presetId: preset.id, data: { name: preset.name, models } }));
  }

  public openCreatePresetDialog(presetId: string = '') {
    this.store.dispatch(presetsActions.openCreatePresetDialog({ presetId }));
  }

  public openEditPresetDialog(presetId: string) {
    this.store.dispatch(presetsActions.openEditPresetDialog({ presetId }));
  }

  public openApplyPresetDialog(cameras: Microsoft.VideoIndexer.Live.Contracts.ICamera[]) {
    this.store.dispatch(presetsActions.openApplyPresetDialog({ cameras }));
  }

  public applyPreset(presetId: string, camerasIds: string[]) {
    this.store.dispatch(presetsActions.applyPreset({ presetId, camerasIds }));
  }

  public managePresets() {
    this.store.dispatch(presetsActions.managePresetsClicked());
  }

  public manageCamerasClicked() {
    this.store.dispatch(cameraActions.manageCamerasClicked());
  }

  public goToCameraPage(cameraId: string) {
    this.store.dispatch(cameraActions.goToCameraPage({ cameraId }));
  }

  public listCameras() {
    this.store.dispatch(cameraActions.listCameras());
  }

  public searchCamera(cameraName: string) {
    this.store.dispatch(cameraActions.searchCameras({ cameraName }));
  }

  public presetsTabChanged(tab: PresetsTab) {
    this.store.dispatch(presetsActions.presetsTabChanged({ tab }));
  }

  public viewCameraSettingsClicked() {
    this.store.dispatch(cameraActions.viewCameraSettingsClicked());
  }

  public unpinCamera(cameraId: string) {
    this.store.dispatch(cameraActions.unpinCamera({ cameraId }));
  }

  public pinCamera(cameraId: string) {
    this.store.dispatch(cameraActions.pinCamera({ cameraId }));
  }

  public updateCamera(settings: ICameraUpdate) {
    this.store.dispatch(cameraActions.updateCamera({ settings }));
  }

  public openDeletePresetDialog(presets: IPresetDetails[]) {
    this.store.dispatch(presetsActions.openDeletePresetDialog({ presets }));
  }

  public goToAioPortalAction() {
    this.store.dispatch(cameraActions.goToAioPortalAction());
  }

  public goToCameraManagementAction() {
    this.store.dispatch(cameraActions.manageCamerasClicked());
  }
}
