import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState } from '../reducers/live-edge.reducer';
import * as fromLive from '../reducers/live-edge.reducer';
import * as fromPresets from './live-presets.selectors';
import * as fromCore from '../../../core/selectors';

export const selectLiveEdgeState = createFeatureSelector<IState>(fromLive.liveFeatureKey);

export const getExtensionDetailsDialogData = createSelector(
  fromCore.isLiveModuleLoaded,
  fromCore.selectedEdgeExtension,
  fromCore.selectedEdgeExtensionTotalIndexedMinutes,
  fromPresets.selectPresetsEntities,
  fromCore.getTenantId,
  (isLiveModuleLoaded, extension, totalIndexedMinutes, presets, tenantId) => {
    if (!isLiveModuleLoaded) {
      return null;
    }

    return {
      tenantId,
      totalIndexedMinutes,
      createdAt: extension?.systemData.createdAt,
      numberOfPresets: Object.entries(presets).length,
      // @TODO: implement after camera management is implemented
      numberOfCameras: 52,
      version: extension?.properties.version,
      extensionResourceId: extension?.id,
      // @TODO: implement after contract with BE is signed
      aioResourceId:
        // eslint-disable-next-line max-len
        '/subscriptions/12345678-1234-1234-1234-123456789012/resourceGroups/vi_arc/providers/Microsoft.ConnectedKubernetes/extensions/My-IOT-account-resource-name'
    };
  }
);
