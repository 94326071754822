import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VIUIModule } from '@vi-ui/src/lib/vi-ui.module';

import { SharedModule } from '@common/modules/shared/shared.module';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { UtilsService } from '@common/modules/shared/services/utils.service';

import { manageAction } from './actions';
import { resources } from './resources';
import { IExtensionDetails } from '../interfaces';

@Component({
  selector: 'vi-extension-details',
  standalone: true,
  templateUrl: './extension-details.component.html',
  styleUrls: ['./extension-details.component.scss'],
  imports: [VIUIModule, SharedModule, CommonModule]
})
export class ExtensionDetailsComponent implements OnInit {
  @Input() details: IExtensionDetails;
  @Output() managePresetsClicked = new EventEmitter();
  @Output() manageCamerasClicked = new EventEmitter();

  public manageAction = manageAction;
  public ActionButtonType = ActionButtonType;
  public resources = resources;

  constructor(private translate: TranslateHelperService, private cdr: ChangeDetectorRef, private utils: UtilsService) {}

  ngOnInit() {
    this.initTranslations();
  }

  public get shouldShowAioResourceId() {
    return this.details.aioResourceId != null;
  }

  public get shouldShowPresets() {
    return this.details.numberOfPresets != null;
  }

  public get shouldShowCameras() {
    return this.details.numberOfCameras != null;
  }

  public get extensionName() {
    return this.parseResourceName(this.details.extensionResourceId);
  }

  public get clusterName() {
    return this.parseResourceName(this.clusterResourceId);
  }

  public get aioName() {
    return this.parseResourceName(this.details.aioResourceId);
  }

  public get clusterResourceId() {
    return this.utils.getParentResourceId(this.details.extensionResourceId);
  }

  public get manageExtensionLink() {
    return `${this.utils.azurePortalLink}/#@${this.details.tenantId}/resource/${this.details.extensionResourceId}`;
  }

  public get manageClusterLink() {
    return `${this.utils.azurePortalLink}/#@${this.details.tenantId}/resource/${this.clusterResourceId}`;
  }

  public get manageAIOLink() {
    return `${this.utils.azurePortalLink}/#@${this.details.tenantId}/resource/${this.details.aioResourceId}`;
  }

  public openLink(link: string) {
    window.open(link, '_blank');
  }

  private parseResourceName(resourceId: string) {
    return resourceId.split('/').pop();
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(this.resources);
    this.manageAction.title = this.resources[this.manageAction.key];

    this.cdr.detectChanges();
  }
}
