import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  AfterViewChecked
} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { TrackService } from '@common/modules/core/services/track';

import { IAction } from '../../interfaces';
import { resources } from './resources';
import { removeHtmlTags } from '../../../utils/string';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { getKeyCode, isMainButtonPressed, KeyCode } from '../../../utils/event';
import { MessageType } from './interfaces';
import { ActionButtonType } from '../action-button/interfaces';

// The strip will be displayed as part of warning/ action or information for the user

@Component({
  selector: 'app-vi-strip',
  templateUrl: './strip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./strip.component.scss']
})
export class StripComponent extends FocusableComponent implements OnInit, AfterViewChecked, OnChanges {
  // Input
  @Input() public text: string;
  // Dispatch an action in case of a button needed
  @Input() public action?: IAction;
  // Display spinner
  @Input() public hasSpinner?: boolean;
  @Input() public hasCloseButton?: boolean;
  @Input() public iconClass?: string;
  @Input() public type?: string;
  @Input() public messageType = MessageType.WARNING;
  // Display progress
  @Input() public progress = false;
  @Input() public loadingProgress?: number;

  @Input() public isAlert = false;
  @Input() public truncate = true;
  // Output
  @Output() public actionClick = new EventEmitter<IAction>();
  @Output() public closeClick = new EventEmitter<Event>();
  @Output() public linkClick = new EventEmitter<Event>();

  public safeText: SafeHtml;
  public resources = resources;
  public ActionButtonType = ActionButtonType;

  private textUpdated = false;

  constructor(
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private translate: TranslateHelperService,
    private loggerService: LoggerService,
    private trackService: TrackService
  ) {
    super();
  }

  public ngOnInit() {
    this.text = this.text || '';
    // eslint-disable-next-line @microsoft/sdl/no-angular-bypass-sanitizer
    this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.text);
    // Remove html tags in text
    this.text = removeHtmlTags(this.text);
    // Translate resources
    this.translate.translateResources(this.resources);
  }

  public ngAfterViewChecked() {
    if (this.textUpdated) {
      this.bindClickEvent();
      this.textUpdated = false;
    }
  }

  public bindClickEvent() {
    const links = this.el.nativeElement.getElementsByClassName('link');

    for (const link of links) {
      // DEPRECATE: bind is deprecated - use arrow function instead
      link.addEventListener('mouseup', this.handleLinkClick.bind(this));
      link.addEventListener('keyup', this.enterLink.bind(this));
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.textUpdated = true;
      // eslint-disable-next-line @microsoft/sdl/no-angular-bypass-sanitizer
      this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.text);
    }
  }

  public handleActionClick() {
    this.actionClick.emit(this.action);
  }

  public closeStrip($event: Event) {
    this.closeClick.emit($event);
  }

  public handleLinkClick($event: MouseEvent) {
    if (isMainButtonPressed($event)) {
      this.linkClick.emit($event);
    }
  }

  private enterLink($event) {
    try {
      const key = getKeyCode($event);
      if (key === KeyCode.Enter) {
        this.linkClick.emit($event);
      }
    } catch (error) {
      this.loggerService.log('Please add key to dictionary.', error);
      this.trackService.trackError(error, { event: $event });
    }
  }
}
