import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

export const manageAction: IAction = {
  icon: 'i-vi-open',
  title: 'manage on azure portal',
  key: 'ManageOnAzurePortal',
  type: UIActionType.CLICK,
  value: 'manage on azure portal',
  id: 'ManageExtension'
};
