import { createAction, props } from '@ngrx/store';

import ICameraUpdate = Microsoft.VideoIndexer.Live.Contracts.ICameraUpdate;

export const manageCamerasClicked = createAction('[Live Cameras] manage cameras clicked');
export const viewCameraSettingsClicked = createAction('[Live Cameras] view camera settings clicked');

export const listCamerasSuccess = createAction(
  '[Live Cameras/API] list cameras success',
  props<{ cameras: Microsoft.VideoIndexer.Live.Contracts.ICamera[] }>()
);
export const listCamerasFailure = createAction('[Live Cameras/API] list cameras failure', props<{ error: string }>());
export const searchCamerasSuccess = createAction(
  '[Live Cameras/API] search cameras success',
  props<{ filteredCameras: Microsoft.VideoIndexer.Live.Contracts.ICamera[] }>()
);
export const searchCamerasFailure = createAction('[Live Cameras/API] search cameras failure', props<{ error: string }>());
export const listCameras = createAction('[Live Cameras/API] list cameras');
export const searchCameras = createAction('[Live Cameras/API] search cameras', props<{ cameraName: string }>());
export const unpinCamera = createAction('[Live Cameras/API] unpin camera', props<{ cameraId: string }>());
export const pinCamera = createAction('[Live Cameras/API] pin camera', props<{ cameraId: string }>());
export const updateCamera = createAction('[Live Cameras/API] update camera', props<{ settings: ICameraUpdate }>());
export const goToCameraPage = createAction('[Live Cameras] go to camera page', props<{ cameraId: string }>());
export const goToAioPortalAction = createAction('[Live Cameras] go to AIO portal page');
export const goToCameraManagementAction = createAction('[Live Cameras] go to camera management tab');
