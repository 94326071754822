import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';
import { selectCurrentAccountId } from './accounts.selectors';
import { getTenantId } from './user.selectors';
import { isGalleryRoute } from '../reducers/router';

export const getEdgeExtensionsState = createSelector(selectCoreState, state => state?.edgeExtensions);

export const selectEdgeExtensionsLoaded = createSelector(getEdgeExtensionsState, state => state?.loaded);

export const selectEdgeExtensionsError = createSelector(getEdgeExtensionsState, state => state?.error);

export const isSelectedEdgeExtensionError = createSelector(getEdgeExtensionsState, state => state?.handshakeFailed);

export const selectedEdgeExtensionId = createSelector(getEdgeExtensionsState, state => state?.selectedExtensionId);

export const hasSelectedEdgeExtension = createSelector(selectedEdgeExtensionId, id => id?.length > 0);

export const selectEdgeExtensions = createSelector(getEdgeExtensionsState, state => Object.values(state.entities));

export const selectedEdgeExtension = createSelector(getEdgeExtensionsState, selectedEdgeExtensionId, (state, id) => state.entities[id]);

export const selectedEdgeExtensionTotalIndexedMinutes = createSelector(
  selectedEdgeExtension,
  extension => extension?.details?.quotaUsage.everUploadDurationInSeconds / 60
);

export const selectedEdgeExtensionName = createSelector(selectedEdgeExtension, extension => extension?.name);

export const selectedEdgeExtensionIotHubResourceId = createSelector(selectedEdgeExtension, extension => extension?.iotHubResourceId);

export const selectedEdgeExtensionHandshakeInProgress = createSelector(getEdgeExtensionsState, state => state?.handshakeInProgress);

export const selectedEdgeExtensionHandshakeDone = createSelector(getEdgeExtensionsState, state => state?.handshakeDone);

export const selectedEdgeExtensionEndpointUrl = createSelector(
  selectedEdgeExtension,
  extension => extension?.properties?.configurationSettings['videoIndexer.endpointUri']
);

export const selectedAccountEdgeExtensions = createSelector(selectEdgeExtensions, selectCurrentAccountId, (extensions, accountId) => {
  return extensions.filter(extension => extension?.properties?.configurationSettings['videoIndexer.accountId'] === accountId);
});

export const hasEdgeExtensions = createSelector(selectedAccountEdgeExtensions, extensions => extensions.length > 0);

export const selectedEdgeExtensionUniqueId = createSelector(selectedEdgeExtension, extension => extension?.identity?.principalId);

export const isExtensionsSubMenuOpened = createSelector(
  hasEdgeExtensions,
  isGalleryRoute,
  (hasEdgeExtensions, isGalleryRoute) => hasEdgeExtensions && isGalleryRoute
);

export const isLiveModuleLoaded = createSelector(getEdgeExtensionsState, state => state?.isLiveModuleLoaded);

export const getExtensionDetailsDialogData = createSelector(
  selectedEdgeExtension,
  selectedEdgeExtensionTotalIndexedMinutes,
  getTenantId,
  (extension, totalIndexedMinutes, tenantId) => {
    if (!extension) {
      return null;
    }

    return {
      tenantId,
      totalIndexedMinutes,
      createdAt: extension?.systemData.createdAt,
      version: extension?.properties.version,
      extensionResourceId: extension?.id
    };
  }
);
