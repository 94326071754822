import { Action, createReducer, on } from '@ngrx/store';

import * as modelsActions from '../actions/live-models.actions';

import BuiltInModel = Microsoft.VideoIndexer.Live.Contracts.BuiltInModel;

export interface IState {
  loaded: boolean;
  isLoading: boolean;
  error: boolean;
  builtInModels: BuiltInModel[];
}

export const initialState: IState = {
  loaded: false,
  isLoading: false,
  error: false,
  builtInModels: []
};

const liveModelsReducer = createReducer(
  initialState,
  on(modelsActions.loadModelsSuccess, (state, { models }) => {
    return {
      ...state,
      error: false,
      loaded: true,
      isLoading: false,
      builtInModels: models
    };
  }),
  on(modelsActions.loadModelsInProgress, state => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(modelsActions.loadModelsFailed, state => {
    return {
      ...state,
      error: true,
      loaded: true,
      isLoading: false,
      builtInModels: []
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return liveModelsReducer(state, action);
}
