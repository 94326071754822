import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  Injector,
  Renderer2,
  HostBinding,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { AriaLivePolitenessEnum } from '@common/modules/editor/interfaces';

import { AccessibilityUtilsService } from '../accessibility-utils.service';
import { AnnouncerDirective } from '../announcer.directive';
import { FocusableComponent } from '../focusable/focusable.component';
import { TranslateHelperService } from '../../translation/services/translate-helper.service';

@Component({
  selector: 'app-accessible-focus-area',
  templateUrl: './accessible-focus-area.component.html'
})
export class AccessibleFocusAreaComponent extends FocusableComponent implements OnInit, OnChanges {
  @Input() public show: boolean;
  @HostBinding('attr.tabindex') public tabindex = 0;
  @ViewChild(AnnouncerDirective, { static: false }) public appAccessibilityAnnouncer: AnnouncerDirective;

  public isActive: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private enteringDiv: any;
  private accessibilityUtils: AccessibilityUtilsService;
  private bClicked = false;

  constructor(
    protected translate: TranslateHelperService,
    protected el: ElementRef,
    protected cdr: ChangeDetectorRef,
    protected renderer: Renderer2
  ) {
    super();
    // Get utils service
    const injector = Injector.create({ providers: [{ provide: AccessibilityUtilsService, deps: [] }] });
    this.accessibilityUtils = injector.get(AccessibilityUtilsService);
  }

  public ngOnInit() {
    this.active(true);
    // If show input is given, and false
    if (this.show !== undefined && !this.show) {
      this.tabindex = -1;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // If shown, add tabIndex
    if (this.show !== undefined && changes.show) {
      this.tabindex = this.show ? 0 : -1;
    }
  }

  @HostListener('keyup.tab', ['$event']) public onFocusInTab(event) {
    this.bClicked = false;
  }

  @HostListener('mousedown', ['$event']) public onClick(event) {
    this.bClicked = true;
  }

  @HostListener('focus', ['$event']) public onFocus(event) {
    // If the was a focus event from mouse / we are not in accessibilityMode(tab/shift+tab pressed)
    if (this.bClicked || !this.accessibilityMode) {
      return;
    }

    this.active(true);

    if (!this.enteringDiv) {
      this.enteringDiv = this.getEnteringDiv();
      this.renderer.insertBefore(this.el.nativeElement, this.enteringDiv, this.el.nativeElement.children[0]);
    }

    this.enteringDiv.hidden = false;
  }

  @HostListener('focusout', ['$event']) public onFocusOut(event) {
    if (event.relatedTarget) {
      const parents = this.accessibilityUtils.getParents(event.relatedTarget);

      // if component focused out-
      if (parents.indexOf(this.el.nativeElement) === -1) {
        this.active(true);
      }
    }

    this.hideEntringDiv();
  }

  protected getCurrentType(): string {
    return '';
  }

  protected active(newValue: boolean) {
    this.isActive = newValue;
  }

  private hideEntringDiv() {
    if (this.enteringDiv) {
      this.renderer.removeChild(this.el.nativeElement, this.enteringDiv);
      this.el.nativeElement.removeEventListener('keyup', this.enteringDivClick);
      this.enteringDiv = null;

      if (this.appAccessibilityAnnouncer) {
        this.appAccessibilityAnnouncer.announce('');
      }
    }
  }

  private enteringDivClick(event) {
    const key = event.which || event.keyCode;
    if (key === 13) {
      // 13 is enter
      this.active(false);
      this.hideEntringDiv();
      this.cdr.markForCheck();
    }
  }

  private getEnteringDiv() {
    // DEPRECATE: bind is deprecated - use arrow function instead
    this.el.nativeElement.addEventListener('keyup', this.enteringDivClick.bind(this));
    const itemResources = { AccessibilityEnteringDivText: '' };
    this.translate.translateResources(itemResources, { name: this.getCurrentType().toLowerCase() });

    if (this.appAccessibilityAnnouncer) {
      this.appAccessibilityAnnouncer.announce(itemResources.AccessibilityEnteringDivText, AriaLivePolitenessEnum.POLITE);
    }
    return this.accessibilityUtils.createEnteringDiv(this.renderer, this.el, itemResources.AccessibilityEnteringDivText);
  }
}
