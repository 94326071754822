import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  AlertMessageComponent,
  InputComponent,
  ShimmerComponent,
  TagComponent,
  NavBarComponent,
  TagContainerComponent,
  LinkComponent,
  TextComponent
} from './components';
import { TextareaComponent } from './components/textarea/textarea.component';
import { LabelComponent } from './components/label/label.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccessibleClickDirective, DebounceDirective } from './directives';
import { SwitchComponent } from './components/switch/switch.component';
import { FocusableComponent } from './components/focusable';
import { CheckboxComponent } from './components/checkbox';
import { RoundedCheckboxComponent } from './components/rounded-checkbox';
import { TableComponent } from './components/table';
import { BadgeComponent } from './components/badge';
import { TableItemSizeDirective } from './components/table/vs-for-table/table-item-size.directive';

@NgModule({
  declarations: [
    AlertMessageComponent,
    AccessibleClickDirective,
    DebounceDirective,
    TagComponent,
    TagContainerComponent,
    InputComponent,
    NavBarComponent,
    LinkComponent,
    TextComponent,
    TextareaComponent,
    LabelComponent,
    ProgressBarComponent,
    AccordionItemComponent,
    AccordionComponent,
    SwitchComponent,
    FocusableComponent,
    ShimmerComponent,
    CheckboxComponent,
    RoundedCheckboxComponent,
    TableComponent,
    BadgeComponent,
    TableItemSizeDirective
  ],
  imports: [CommonModule, FormsModule, MatProgressBarModule, MatTableModule, MatSortModule, ScrollingModule],
  exports: [
    AlertMessageComponent,
    TagComponent,
    TagContainerComponent,
    NavBarComponent,
    InputComponent,
    TextareaComponent,
    LinkComponent,
    TextComponent,
    AccessibleClickDirective,
    LabelComponent,
    ProgressBarComponent,
    AccordionItemComponent,
    AccordionComponent,
    SwitchComponent,
    FocusableComponent,
    ShimmerComponent,
    CheckboxComponent,
    RoundedCheckboxComponent,
    TableComponent,
    BadgeComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VIUIModule {}
